<template>
  <v-row
    class="pb-8"
  >
    <v-col
      cols="12"
      sm="12"
      class="pt-0"
    >
      <carousel />
      <v-row>
        <v-col
          cols="0"
          md="8"
          sm="7"
          lg="9"
          xl="10"
        >
          <session-title
            class="pt-8 px-8 pb-0"
            title="Holerites"
          />
        </v-col>
        <v-col
          class="pb-4 px-13 pt-3"
          cols="12"
          md="4"
          sm="5"
          lg="3"
          xl="2"
          align-self="center"
        >
          <chat-ti-modal-access-vue />
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      class="pt-0 px-12"
    />
    <v-col
      cols="12"
      sm="12"
      class="pt-0 px-12"
    >
      <div
        class="text-center"
      >
        <v-alert 
          border="left" 
          color="blue" 
          dense 
          type="info"
          text 
        >Se o holerite não for gerado, pode ser que a folha de pagamentos esteja em processamento. Por favor, tente novamente mais tarde.</v-alert
        >
        <v-select
          v-model="selected"
          :items="items"
          label="Selecione o mês"
          item-value="value"
          item-text="label"
          outlined
          color="blue darken-4"
        />
        <v-btn
          color="secondary"
          elevation="2"
          large
          @click="generatePayslip"
        >
          <v-icon class="mr-3">
            mdi-file-move-outline
          </v-icon>Gerar Holerite
        </v-btn>
        <v-btn
          class="ml-4"
          color="secondary"
          elevation="2"
          large
          @click="generateThirteenFirstSalary"
        >
          <v-icon class="mr-3">
            mdi-file-move-outline
          </v-icon>Gerar Holerite Décimo Terceiro
        </v-btn>
        
        <v-alert
          v-if="loading"
          outlined
          type="warning"
          class="mt-5 text-center text-justify"
          prominent
        >
          Esse processo pode levar alguns minutos. Por favor, aguarde.
        </v-alert>
        <v-alert
          border="left" 
          color="blue" 
          dense 
          type="info"
          text
          class="mt-5 text-center text-justify"
        >Para gerar o espelho de ponto, seu navegador precisa aceitar janelas popup.
          Para desbloquear acesse as Configurações > Privacidade e Segurança > Permissões e desmarque a caixa "Bloquear janelas popup".
        </v-alert>
        
      </div>
    </v-col>
  </v-row>
</template>

<script>
import * as dayjs from 'dayjs'
import SessionTitle from '@/components/SessionTitle'
import { monthsPTBR } from '../../utils/months'
import { getPayslipByDate } from '../../services/payslips'
import { mapState } from 'vuex'
import Carousel from '@/components/Carousel'
import ChatTiModalAccessVue from '../../components/ModalViews/ChatTiModalAccess.vue'

export default {
  name: 'Payslips',
  components: {
    carousel: Carousel,
    'session-title': SessionTitle,
    'chat-ti-modal-access-vue': ChatTiModalAccessVue
  },
  data () {
    return {
      items: [],
      selected: null,
      calculationType: 0,
      thirteenSalaryYear: null,
      currentMonth: null,
      lastYear: null
    }
  },
  computed: {
    ...mapState('loader', ['loading'])
  },
  created () {
    const currentDate = dayjs()
    const maxMonthsAllowed = 12
    this.currentYear = currentDate.year();
    this.currentMonth = currentDate.month() + 1;
    this.lastYear = currentDate.year() - 1;

    for (let index = 1; index <= maxMonthsAllowed; index++) {
      const monthNumber = currentDate.subtract(index, 'month').month()
      const fullYear = currentDate.subtract(index, 'month').year()

      this.items.push({
        label: `${monthsPTBR[monthNumber]} - ${fullYear}`,
        value: {
          month: monthNumber + 1,
          year: fullYear,
        }
      })
    }
  },
  methods: {
    isDisabled () {
      if(this.selected == null) return false
      return this.selected.month == 12
    },
    async generatePayslip () {
      if (!this.isFieldValid()) return
      try {
        this.calculationType = 11
        const data = await getPayslipByDate({month: this.selected.month, year: this.selected.year, calculationType: this.calculationType})
        const file = new Blob([data], { type: 'application/pdf' })
        window.open(URL.createObjectURL(file))
      } catch (error) {
        this.$handleHttpError(error)
      }
    },
    async generateThirteenFirstSalary(){
      if(this.currentMonth < 12){
        try {
          this.calculationType = 32
          const data = await getPayslipByDate({month: 12, year: this.lastYear, calculationType: this.calculationType})
          const file = new Blob([data], { type: 'application/pdf' })
          window.open(URL.createObjectURL(file))
        } catch (error) {
          this.$handleHttpError(error)
        }
      }
      else{
        try {
          this.calculationType = 32
          const data = await getPayslipByDate({month: 12, year: this.currentYear, calculationType: this.calculationType})
          const file = new Blob([data], { type: 'application/pdf' })
          window.open(URL.createObjectURL(file))
        } catch (error) {
          this.$handleHttpError(error)
        }
      }
    },
    isFieldValid () {
      if (!this.selected) {
        this.$toast.error('Verifique o campo e tente novamente')
      }
      return !!this.selected
    }

  }
}
</script>
<style>

.v-alert{
  font-size: 0.8em;
}

</style>
